import { lazy, ReactElement, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Link, LoadingProgress } from '@listingops/react';

const Address = lazy(() => import('./AddressPage'));
const Agent = lazy(() => import('./AgentPage'));
const Agents = lazy(() => import('./AgentsPage'));
const Dashboard = lazy(() => import('./DashboardPage'));
const Geo = lazy(() => import('./GeoPage'));
const Layout = lazy(() => import('./Layout'));
const Login = lazy(() => import('./LoginPage'));
const Reset = lazy(() => import('./ResetPage'));
const NotFound = lazy(() => import('./NotFoundPage'));

type MenuLink = Link & { element: ReactElement | null };

const links: Link[] = [
  { path: '/dashboard/agents', title: 'Agents' },
  { path: '/dashboard/geo', title: 'Geo' },
];

const handleMenuEle = (index: number): ReactElement | null => {
  switch (index) {
    case 0: {
      return <Agents />;
    }
    case 1: {
      return <Geo />;
    }
    default:
      return null;
  }
};

const menuLinks = links.map<MenuLink>(({ path, title }, i) => ({
  element: handleMenuEle(i),
  path,
  title,
}));

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<LoadingProgress isLoading />}>
      <Routes>
        <Route element={<Layout links={links} />}>
          <Route path='/dashboard' element={<Dashboard />} />
          {menuLinks.map(({ element, path, title }) => (
            <Route key={title} element={element} path={path} />
          ))}
          <Route path='/dashboard/agent/:id' element={<Agent />} />
          <Route path='/dashboard/address/:id' element={<Address />} />
        </Route>
        <Route path='/' element={<Login />} />
        <Route path='/reset' element={<Reset />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
