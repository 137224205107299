import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { AgentDocument } from '@listingops/types';
import { GridRowModel, GridRowId } from '@mui/x-data-grid';

type SelectedState = { ids: GridRowId[]; rows: GridRowModel[] };

type AppContextProps = {
  agent: AgentDocument | null;
  setAgent: Dispatch<SetStateAction<AgentDocument | null>>;
  selected: SelectedState;
  setSelected: Dispatch<SetStateAction<SelectedState>>;
};

const initialSelected: SelectedState = { ids: [], rows: [] };

const AppContext = createContext<AppContextProps>({
  agent: null,
  setAgent: () => undefined,
  selected: initialSelected,
  setSelected: () => undefined,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [agent, setAgent] = useState<AgentDocument | null>(null);
  const [selected, setSelected] = useState<SelectedState>(initialSelected);

  const value: AppContextProps = { agent, setAgent, selected, setSelected };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
